/**
 * @license
 * MyFonts Webfont Build ID 3544488, 2018-03-20T05:28:16-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-35-light-115678/
 * 
 * Webfont: AvenirLTPro-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-55-roman-115678/
 * 
 * Webfont: AvenirLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-65-medium-115678/
 * 
 * Webfont: AvenirLTPro-Heavy by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir/pro-85-heavy-115678/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3544488
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */

@import url("//hello.myfonts.net/count/3615a8");

@font-face {
  font-family: 'Avenir';
  src: url('#{$fontPath}3615A8_0_0.eot');
  src: url('#{$fontPath}3615A8_0_0.eot?#iefix') format('embedded-opentype'), url('#{$fontPath}3615A8_0_0.woff2') format('woff2'), url('#{$fontPath}3615A8_0_0.woff') format('woff'), url('#{$fontPath}3615A8_0_0.ttf') format('truetype'), url('#{$fontPath}3615A8_0_0.svg#wf') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('#{$fontPath}3615A8_1_0.eot');
  src: url('#{$fontPath}3615A8_1_0.eot?#iefix') format('embedded-opentype'), url('#{$fontPath}3615A8_1_0.woff2') format('woff2'), url('#{$fontPath}3615A8_1_0.woff') format('woff'), url('#{$fontPath}3615A8_1_0.ttf') format('truetype'), url('#{$fontPath}3615A8_1_0.svg#wf') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('#{$fontPath}3615A8_2_0.eot');
  src: url('#{$fontPath}3615A8_2_0.eot?#iefix') format('embedded-opentype'), url('#{$fontPath}3615A8_2_0.woff2') format('woff2'), url('#{$fontPath}3615A8_2_0.woff') format('woff'), url('#{$fontPath}3615A8_2_0.ttf') format('truetype'), url('#{$fontPath}3615A8_2_0.svg#wf') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('#{$fontPath}3615A8_3_0.eot');
  src: url('#{$fontPath}3615A8_3_0.eot?#iefix') format('embedded-opentype'), url('#{$fontPath}3615A8_3_0.woff2') format('woff2'), url('#{$fontPath}3615A8_3_0.woff') format('woff'), url('#{$fontPath}3615A8_3_0.ttf') format('truetype'), url('#{$fontPath}3615A8_3_0.svg#wf') format('svg');
  font-weight: 800;
  font-style: normal;
}
