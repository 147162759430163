@import "base";
@import "variables";
@import "default";
@import "mixins";
@import "family";
@import "fonts";
@import "icons";
@import "forms";

/* General styles */
/* ---------------------------------- */

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

*:focus {
  outline: none;
}

html {
  //overflow-x: hidden !important;
  //overflow-y: visible !important;
}

body.admin-body .select2-container--default .select2-dropdown,
body.admin-body .select2-container .select2-dropdown {
  margin-top: 32px;
}

body {
  background-color: $lightMedium;
  font-family: 'Avenir', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  overflow-x: hidden !important;

  /* WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* IE. */
  font-feature-settings: 'liga';
}

.page-ctn {
  max-width: 1328px;
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
}

.no-scroll {
  //position: fixed;
  height: 100vh;
  overflow: hidden;
}

a,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

a {
  cursor: pointer;
  transition: all .35s ease-in-out;
}

img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  //image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  //-ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  &.hidden{
    display: none;
  }
}

svg {
  display: inline-block;
  transition: all .35s ease-in-out;
}

.container {
  box-sizing: content-box;
  width: calc(100% - 60px);
  max-width: 1230px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 479px) {
    width: calc(100% - 30px);
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container-mini {
  max-width: 710px;
  margin-left: auto;
  margin-right: auto;
}

.container-medium {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.hidden-desktop {
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.hidden-mobile {
  @media screen and (max-width: 991px) {
    display: none;
  }
}

/* Title */
/* ---------------------------------- */

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {

}

h1, .h1 {
  font-size: 48px;
  font-weight: 300;
  line-height: 1.17;

  @media screen and (min-width: 992px) and (max-width: 1199px) {

  }
  @media screen and (min-width: 768px) and (max-width: 991px) {

  }
  @media screen and (max-width: 767px) {
    font-size: 30px;
  }
}

h2, .h2 {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.25;

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 28px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
  }
  @media screen and (max-width: 767px) {
    font-size: 25px;
  }
}

h3, .h3 {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.33;
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {

  }
  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
}

h4, .h4 {
  color: $white;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
}

p {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  em {
    color: black;
    font-style: italic;
  }

  a {
    color: $brand;
    border-bottom: 1px solid transparent;
    &:hover {
      color: $brand;
      border-bottom-color: $brand;
    }
  }

  &.iframe-ctn {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 56.25%;
    }
    iframe, embed, object {
      @include absCenter();
      width: 100%;
      height: 100%;
    }
  }

  iframe {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

p + h3 {
  margin-top: 33px;
}

p + .btn {
  margin-top: 33px;
}

h2 + form {
  margin-top: 40px;
}

.site-content {
  ul {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    li {
      padding-left: 1em;
      &:before {
        color: $brand;
        content: "•";
        float: left;
        margin-left: -1em;
        position: relative;
        top: 1px;
      }
    }
  }

}

em {
  color: $brand;
  font-style: normal;
}

strong {
  font-weight: 800;
}

.btn-ctn {
  text-align: center;
}

.btn {
  display: inline-block;
  cursor: pointer;
  width: auto;
  padding: 21px 20px 19px;
  background-color: $brand;
  text-decoration: none;
  text-align: center;
  color: $white;
  font-size: 13px;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;

  .ico {
    margin-left: 15px;
  }

  @media screen and (max-width: 479px) {
    position: relative;
    display: block;
    width: 100%;
    padding-right: 80px;
    text-align: left;
    .ico {
      position: absolute;
      top: 50%;
      right: 20px;
      @include translateY(-50%);
    }
  }

  &:hover {
    color: $white;
    background-color: darken($brand, 10%);
    .ico {
      @media screen and (min-width: 480px) {
        @include translateX(4px);
      }
    }
  }
}

.overlay {
  @include flex(center, center, row);
  @include absCenter();
  @include square(100%);
  background-color: rgba($purple, .8);
  //transition: all .35s ease-in-out;
}

/* Slick */
/* ---------------------------------- */

body {
  .slick-slider.slick-dotted {
    margin-bottom: 0;
  }
  .slick-dots {
    width: 100%;
    @include flex(center, center, row);
    position: absolute;
    bottom: 30px;
    margin: 15px 0 0;

    @media screen and (min-width: 480px) and (max-width: 1079px) {
      margin-top: 50px;
    }
    @media screen and (max-width: 479px) {
      margin-top: 25px;
    }
    li {
      padding: 0;
      background: none;
      width: auto;
      height: auto;
      margin: 0 3px;
      line-height: normal;
      &:not(:last-child) {
        margin-bottom: 0;
      }
      &:not(.slick-active) {
        button {
          padding: 0;
          text-indent: -9919px;
          @include square(6px);
          border-radius: 50%;
          background-color: $white;
        }
      }
      &.slick-active {
        button {
          width: 17px;
          height: 6px;
          border-radius: 100px;
          //background-color: $blue;
        }
      }
      button {
        outline: none;
        padding: 0;
        transition: all .35s ease-in-out;
        text-indent: -9919px;
        &:hover {
          //background-color: $blue;
        }
        &:before {
          display: none;
        }
      }
    }
  }
}

/* Flex helpers */
/* ---------------------------------- */

.ctn-flex {
  @include flex(space-between, center, row);
  margin-left: -15px;
  margin-right: -15px;
  @include flexWrap(wrap);
  &.ctn-flex-top {
    @include alignItems(flex-start);
  }
  &.ctn-flex-bottom {
    @include alignItems(flex-end);
  }
  &.ctn-flex-column {
    @include flexDirection(column);
  }
  &.ctn-flex-initial {
    @include alignItems(inherit);
  }
  &.ctn-flex-stretch {
    @include alignItems(stretch);
  }
  &.ctn-flex-start {
    @include justifyContent(flex-start);
  }
  &.ctn-flex-end {
    @include justifyContent(flex-end);
  }
  &.ctn-flex-center {
    @include justifyContent(center);
  }
  &.ctn-flex-mini {
    margin-left: -8px;
    margin-right: -8px;
  }
  &.ctn-flex-no-mg {
    margin-left: 0;
    margin-right: 0;
  }
  &.ctn-flex-nowrap {
    @include flexWrap(nowrap);
  }
  &.ctn-flex-row-reverse {
    @include flexDirection(row-reverse);
  }
  &.ctn-flex-card {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.align-self-top {
  @include alignSelf(flex-start);
}

.layout {
  @include flexWrap(nowrap);

  @media screen and (max-width: 991px) {
    display: block;
  }

  > * {
    margin-left: 15px;
    margin-right: 15px;

    iframe {
      width: 100%;
      @media screen and (min-width: 480px) and (max-width: 767px) {
        margin-left: -30px;
        margin-right: -30px;
        width: calc(100% + 60px);
      }
      @media screen and (max-width: 479px) {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
      }
    }
  }
  &.layout--3-2 {
    .layout-main {
      width: calc(60% - 30px);
      min-width: calc(60% - 30px);
      max-width: calc(60% - 30px);
      @include flexGrow(0 1 calc(60% - 30px));
      @media screen and (max-width: 991px) {
        @include flexGrow(1);
        width: calc(100% - 30px);
        min-width: calc(100% - 30px);
        max-width: calc(100% - 30px);
        margin-bottom: 30px;
      }
    }
    .layout-aside {
      width: 300px;
      min-width: 300px;
      max-width: 300px;
      @include flexGrow(0 1 300px);
      @media screen and (max-width: 991px) {
        @include flexGrow(1);
        width: calc(100% - 30px);
        min-width: calc(100% - 30px);
        max-width: calc(100% - 30px);
      }
    }
  }

  &.layout--2-2 {
    .layout-main {
      @media screen and (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
    .layout-main,
    .layout-aside {
      width: calc(50% - 30px);
      min-width: calc(50% - 30px);
      max-width: calc(50% - 30px);
      @include flexGrow(0 1 calc(50% - 30px));
      @media screen and (max-width: 991px) {
        @include flexGrow(1);
        width: calc(100% - 30px);
        min-width: calc(100% - 30px);
        max-width: calc(100% - 30px);
      }
    }
    .layout-aside {
      .bloc-cross-content {
        margin-top: 0;
        padding-bottom: 0;
        border-bottom: none;
        h3 {
          margin-bottom: 20px;
        }
      }
    }
  }
}

/* Forms */
/* ---------------------------------- */

.subheader-form,
.newsletter-form,
.search-form {
  @include flex(center, flex-end, row);

  label,
  input,
  button {
    border: 0;
    color: $white;
    display: inline-block;
    vertical-align: bottom;
    border-bottom: 1px solid $white;
  }

  input, label {
    padding-top: 10px;
    padding-bottom: 8px;
    padding-right: 12px;
    background: none !important;
    @include flexGrow(1);
    font-family: 'Avenir', sans-serif;
    color: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;

    &::-webkit-input-placeholder {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
    &:-moz-placeholder {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
    &::-moz-placeholder {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
    &:-ms-input-placeholder {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  button {
    position: relative;
    background: none;
    padding: 10px 12px 8px;
    cursor: pointer;
    font-family: 'Avenir', sans-serif;
    color: $white;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.5;
    text-transform: uppercase;
    transition: all .35s ease-in-out;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 0;
      background-color: $white;
      transition: all .35s ease-in-out;
    }
    .ico {
      margin-left: 10px;
    }
    &:hover {
      &:after {
        height: 5px;
      }
      .ico {
        @include translateX(4px);
      }
    }
  }
}

.newsletter-form {
  button {
    margin-top: 0;
  }
}

.subheader-form {
  margin-bottom: 24px;
  @include justifyContent(space-between);
  @media screen and (min-width: 992px) {
    border-bottom: 1px solid #d7d7d7;
  }
  @media screen and (min-width: 800px) and (max-width: 991px) {
    @include flexWrap(wrap);
    border-bottom: 1px solid #d7d7d7;
    @include justifyContent(space-between);
  }
  @media screen and (max-width: 799px) {
    display: block;
  }

  .ctn-flex.ctn-flex-end {
    @include flexGrow(inherit);
    margin-left: 0;
    margin-right: 0;
  }

  label {
    @include flexGrow(inherit);
  }

  label,
  input,
  button {
    color: $grey;
    border-bottom-color: #d7d7d7;
    @media screen and (min-width: 800px) and (max-width: 991px) {
      border-bottom: none;
    }
    @media screen and (min-width: 992px) {
      border-bottom: none;
    }
  }

  input, label {
    padding: 24px 20px 22px;
    color: $grey;
    font-size: 13px;

    @media screen and (min-width: 800px) and (max-width: 1199px) {
      font-size: 12px;
    }

    &::-webkit-input-placeholder {
      color: $grey;
      font-size: 13px;
      text-transform: uppercase;
      @media screen and (min-width: 800px) and (max-width: 1199px) {
        font-size: 12px;
      }
    }
    &:-moz-placeholder {
      color: $grey;
      font-size: 13px;
      text-transform: uppercase;
      @media screen and (min-width: 800px) and (max-width: 1199px) {
        font-size: 12px;
      }
    }
    &::-moz-placeholder {
      color: $grey;
      font-size: 13px;
      text-transform: uppercase;
      @media screen and (min-width: 800px) and (max-width: 1199px) {
        font-size: 12px;
      }
    }
    &:-ms-input-placeholder {
      color: $grey;
      font-size: 13px;
      text-transform: uppercase;
      @media screen and (min-width: 800px) and (max-width: 1199px) {
        font-size: 12px;
      }
    }
  }

  label {
    text-transform: uppercase;
  }

  > label {
    @media screen and (min-width: 800px) and (max-width: 991px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      @include flexGrow(0 1 100%);
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
    @media screen and (max-width: 799px) {
      width: 100%;
      text-align: center;
    }
  }

  .radio,
  .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #d7d7d7;
    @media screen and (min-width: 800px) and (max-width: 991px) {
      border-bottom: none;
    }
    @media screen and (min-width: 992px) {
      border-bottom: none;
    }
    label {
      border-bottom: none;
      color: $black;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
      padding-left: 0;
      @media screen and (min-width: 800px) and (max-width: 1199px) {
        font-size: 12px;
      }
    }
    span {
      @include square(24px);
      min-width: 24px;
      max-width: 24px;
      @include flexGrow(0 1 24px);
      margin-right: 16px;
      border-radius: 3px;
      border: 2px solid #d7d7d7;
      @media screen and (max-width: 799px) {
        margin-left: 15px;
      }
      &:before {
        @include square(12px);
        border-radius: 2px;
      }
    }
  }

  button {
    position: relative;
    margin-top: 0;
    padding: 24px 40px 22px;
    color: $brand;
    font-size: 13px;
    &:before {
      position: absolute;
      left: 0;
      top: 50%;
      @include translateY(-50%);
      content: '';
      display: block;
      width: 1px;
      height: 36px;
      background-color: #d7d7d7;
    }
    &:after {
      background-color: $brand;
    }
    .ico {
      fill: $brand;
    }
  }

}

.site-content--products {
  .subheader-form {
    @media screen and (min-width: 1075px) and (max-width: 1279px) {
      @include flexWrap(wrap);
      border-bottom: 1px solid #d7d7d7;
      @include justifyContent(center);
    }
    @media screen and (max-width: 1074px) {
      display: block;
      border-bottom: none;
    }
    label,
    input,
    button {
      @media screen and (min-width: 1075px) and (max-width: 1279px) {
        border-bottom: none;
      }
      @media screen and (max-width: 1074px) {
        border-bottom: 1px solid #d7d7d7;
      }
    }

    input, label {
      @media screen and (min-width: 800px) and (max-width: 1279px) {
        font-size: 12px;
      }

      &::-webkit-input-placeholder {
        @media screen and (min-width: 800px) and (max-width: 1279px) {
          font-size: 12px;
        }
      }
      &:-moz-placeholder {
        @media screen and (min-width: 800px) and (max-width: 1279px) {
          font-size: 12px;
        }
      }
      &::-moz-placeholder {
        @media screen and (min-width: 800px) and (max-width: 1279px) {
          font-size: 12px;
        }
      }
      &:-ms-input-placeholder {
        @media screen and (min-width: 800px) and (max-width: 1279px) {
          font-size: 12px;
        }
      }
    }
    > label {
      @media screen and (min-width: 1075px) and (max-width: 1279px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        @include flexGrow(0 1 100%);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }
      @media screen and (max-width: 1074px) {
        width: 100%;
        text-align: center;
        padding: 24px 20px 22px;
      }
    }
    .radio,
    .checkbox {
      @media screen and (min-width: 1075px) and (max-width: 1279px) {
        border-bottom: none;
      }
      @media screen and (max-width: 1074px) {
        border-bottom: 1px solid #d7d7d7;
      }
      label {
        @media screen and (min-width: 800px) and (max-width: 1279px) {
          font-size: 12px;
        }
        @media screen and (max-width: 1074px) {
          border-bottom: none;
        }
      }
      span {
        @media screen and (max-width: 1074px) {
          margin-left: 15px;
        }
      }
    }
  }
}

body {
  .site-content--products {
    .subheader-form {
      .select2-container, .select2-container--default {
        width: auto;
        min-width: 210px;
        max-width: inherit;
        @include flexGrow(0 1 auto);
        @media screen and (max-width: 1074px) {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          @include flexGrow(0 1 100%);
        }

        &:first-of-type {
          &:before {
            @media screen and (min-width: 800px) and (max-width: 1279px) {
              display: none;
            }
          }
        }
        &:last-of-type {
          @media screen and (min-width: 1075px) and (max-width: 1279px) {
            margin-right: 30px;
          }
          &:after {
            @media screen and (min-width: 1075px) and (max-width: 1279px) {
              position: absolute;
              right: 0;
              top: 50%;
              @include translateY(-50%);
              content: '';
              display: block;
              width: 1px;
              height: 36px;
              background-color: #d7d7d7;
            }
          }
        }

        &:before {
          @media screen and (max-width: 1074px) {
            display: none;
          }
        }

        .select2-selection--single {
          @media screen and (min-width: 1075px) and (max-width: 1279px) {
            border-bottom: none;
          }
          @media screen and (max-width: 1074px) {
            border-bottom: 1px solid #d7d7d7;
          }
          .select2-selection__rendered {
            padding-left: 20px;
            padding-right: 54px;
            @media screen and (min-width: 800px) and (max-width: 1279px) {
              font-size: 12px;
            }
          }
          .select2-selection__arrow {
            right: 17px;
          }
        }
      }
    }
  }
  .subheader-form {
    .select2-container, .select2-container--default {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
      @include flexGrow(0 1 230px);

      @media screen and (min-width: 800px) and (max-width: 1199px) {
        width: 180px;
        min-width: 180px;
        max-width: 180px;
        @include flexGrow(0 1 180px);
      }
      @media screen and (max-width: 799px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        @include flexGrow(0 1 100%);
      }

      &.select2-container--open {
        .select2-selection--single {
          .select2-selection__arrow {
            b {
              border-color: transparent transparent $brand transparent;
            }
          }
        }
      }

      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        @include translateY(-50%);
        content: '';
        display: block;
        width: 1px;
        height: 36px;
        background-color: #d7d7d7;
        @media screen and (max-width: 799px) {
          display: none;
        }
      }
      .select2-selection--single {
        height: 66px;
        border: none;
        border-bottom: 1px solid #d7d7d7;
        @media screen and (min-width: 800px) and (max-width: 991px) {
          border-bottom: none;
        }
        @media screen and (min-width: 992px) {
          border-bottom: none;
        }
        .select2-selection__rendered {
          color: $black;
          font-size: 13px;
          font-weight: 800;
          line-height: 70px;
          text-transform: uppercase;
          padding-right: 48px;

          @media screen and (min-width: 800px) and (max-width: 1199px) {
            font-size: 12px;
          }
          @media screen and (max-width: 799px) {
            padding-left: 15px;
          }
        }
        .select2-selection__arrow {
          right: 15px;
          b {
            border-color: $brand transparent transparent transparent;
          }
        }
      }
    }
  }
}

/* Breadcrumb styles */
/* ---------------------------------- */

.breadcrumbs, #breadcrumbs {
  @media screen and (max-width: 767px) {
    display: none;
  }

  a, strong, span {
    //color: $grey;
    //font-style: normal;
    //font-weight: 400;

    &:not(:first-child) {
      padding-left: 2px;
    }
    &:not(:last-child) {
      padding-right: 2px;
    }
  }
  a {
    border-bottom: none;
    &:hover {
      //color: $darkGrey;
    }
  }
  .breadcrumb_last {
    //font-weight: 600;
  }
}

.breadcrumbs {
  padding-top: 20px;
  padding-bottom: 18px;
  //border-bottom: 1px solid $light;
}

/* Accordion */
/* ---------------------------------- */

.accordion-items {
  margin-top: 11px;
  .accordion-item {
    border-bottom: 2px solid #d7d7d7;
    transition: all .35s ease-in-out;
    &.active, &:hover {
      .accordion-item-question {
        color: $brand;
      }
    }
    &.active {
      border-bottom-color: $brand;
      .ico-arrow-faq {
        fill: $brand;
        @include rotate(180deg);
      }
    }
    &:last-child {
      @media screen and (max-width: 991px) {
        .accordion-item-question {
          border-bottom: none;
        }
      }
    }
    .ico-arrow-down {
      width: 18px;
      min-width: 18px;
      max-width: 18px;
      @include flexGrow(0 1 18px);
    }
  }
  .accordion-item-question {
    cursor: pointer;
    padding-top: 24px;
    padding-bottom: 24px;
    transition: all .35s ease-in-out;
    h3 {
      margin: 0;
      &:first-of-type {
        padding-right: 30px;
        @media screen and (max-width: 639px) {
          padding-right: 15px;
        }
      }
    }
  }
  .accordion-item-answer {
    padding: 0 0 35px 0;

  }
}

/* Modal */
/* ---------------------------------- */

button.close {
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  cursor: pointer;
  z-index: 3;
  height: 20px;
  vertical-align: middle;
  background-color: transparent;
  transition: .6s ease-in-out;

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }

  span {
    display: block;
    position: absolute;
    opacity: 1;
    transition: all .3s ease-in-out;
    background-color: #fff;
    width: 30px;
    height: 2px;
    left: 5px;
    transform-origin: left center;
    &:first-child {
      @include rotate(45deg);
      top: -1px;
    }
    &:last-child {
      @include rotate(-45deg);
      top: 20px;
    }
  }
}

.modal-open {
  overflow: hidden;
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  &:before {
    @media screen and (min-width: 768px) {
      height: 100%;
    }
  }
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 76rem;
    max-height: none
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}

.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

#modal-video {
  .container-medium {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.modal-backdrop.in {
  opacity: 0.95;
}

.modal-backdrop {
  background-color: $pinkDark;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.modal {
  text-align: center;
  padding: 0 !important;

  h2 {
    margin-top: 0;
  }

  h4 {
    font-size: 2.8rem;
  }

  h5 {
    margin-top: 2rem;
    font-size: 1.6rem;
  }

  h4, h5, h6 {
    color: $grey;
    text-transform: uppercase;
    font-weight: 700;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 1.5rem;
  }

  h5 + p {
    font-size: 1.4rem;
  }

}

.modal:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-right: -0.4rem;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 94%;
  max-height: 91vh;
  margin: 4rem 1rem 1rem;
}

.modal-content {
  position: relative;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  padding: 0 1.5rem;
  outline: 0;

  .modal-header {
    padding: 3.5rem 0 2.5rem;
  }

  .modal-body {
    padding: 2.5rem 0 3.5rem;

    strong {
      color: $grey;
    }
  }

  &.no-padding {
    padding: 0;

    .modal-body, .modal-header {
      padding: 0;
      border: none;
    }
  }

  .form-group {
    label {
      color: $white;
    }

    input[type="submit"],
    button[type="submit"] {
      background-color: $white;
      //color: $blue;
    }

    input:not([type="submit"]), textarea {
      color: $white;

      &::-webkit-input-placeholder {
        color: $white;
      }
      &:-moz-placeholder {
        color: $white;
      }
      &::-moz-placeholder {
        color: $white;
      }
      &:-ms-input-placeholder {
        color: $white;
      }
    }
  }
}

.close {
  //color: $darkGrey;
  margin-top: 0;
  position: absolute;
  text-shadow: none;
  opacity: 1;
  font-size: 1.2rem;
  text-transform: uppercase;
  right: -1.5rem;
  top: -2.5rem;

  span:not(.icon-plus) {
    display: inline-block;
    letter-spacing: 0.1rem;
  }
}

#modal-search {

  .modal-dialog {
    margin: 0;
  }

  .modal-content {
    border: 0 !important;
    background: none !important;
  }

}

.modal.thumb img {
  width: 100%;
  height: auto;
}

/* Header */
/* ---------------------------------- */

.header-push {
  background-color: $white;
}

.site-header {
  background-color: $white;
  @media screen and (max-width: 991px) {
    -webkit-box-shadow: 0 0 22px -2px rgba($black, .75);
    -moz-box-shadow: 0 0 22px -2px rgba($black, .75);
    box-shadow: 0 0 22px -2px rgba($black, .75);
  }
  &.sticky {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-box-shadow: 0 0 22px -2px rgba($black, .75);
    -moz-box-shadow: 0 0 22px -2px rgba($black, .75);
    box-shadow: 0 0 22px -2px rgba($black, .75);

    & + .header-push {
      margin-bottom: 71px;
      @media screen and (max-width: 991px) {
        margin-bottom: 64px;
      }
    }

    .btn-search {
      visibility: hidden;
      opacity: 0;
    }
    .header-inner {
      @include alignItems(center);
      //padding-top: 8px;
      //padding-bottom: 8px;
      padding-top: 0;
      padding-bottom: 0;
      @media screen and (max-width: 991px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .header-logo {
      padding-bottom: 0;
      a {
        width: 100px;
        transition: all .3s ease-in-out;
        @media screen and (max-width: 991px) {
          width: 90px;
        }
      }
    }
    .header-menu {
      /*     li {
             &.menu-item-has-children {
               > a:after {
                 bottom: -18px;
               }
             }
           }*/
      > li > a {
        padding: 23px 15px;
      }
    }
  }

  &.open {
    .header-toggle {
      span {
        width: 30px;
        left: 0;
        &:first-of-type {
          @include rotate(33deg);
          top: -1px;
        }
        &:nth-of-type(2) {
          display: none;
        }
        &:last-of-type {
          @include rotate(-33deg);
          top: 15px;
        }
      }
    }
    .header-menu {
      @include translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  .container {
    position: relative;
  }
  .header-inner {
    position: relative;
    margin-top: -35px;
    padding-top: 30px;
    //padding-bottom: 15px;
    @media screen and (max-width: 991px) {
      margin-top: 0;
      padding-top: 19px;
      padding-bottom: 17px;
      @include alignItems(center);
    }
  }
  .btn-search {
    position: relative;
    z-index: 11;
    margin-left: auto;
    max-width: 140px;
    @include flex(space-between, center, row);
    padding: 8px 24px 8px 22px;
    background-color: $light;
    color: #888;
    font-size: 13px;
    font-weight: 500;
    @media screen and (max-width: 991px) {
      display: none;
    }
    .ico {
      @include square(14px);
      fill: $greyMedium;
    }
  }
  .header-logo { //h1
    margin: 0;
    padding: 0 0 18px;
    font-size: 10px;
    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
    a {
      position: relative;
      display: block;
      width: 180px;
      &:before {
        content: '';
        display: block;
        padding-bottom: 47.5%;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        width: 135px;
      }
      @media screen and (max-width: 991px) {
        width: 90px;
      }
      &:hover {
        border: none;
      }
    }
    svg, img {
      @include absCenter();
      width: 100%;
      height: auto;
    }
  }
  .header-toggle {
    cursor: pointer;
    z-index: 4;
    display: none;
    position: relative;
    height: 17px;
    width: 26px;
    vertical-align: middle;
    transition: .6s ease-in-out;

    @media screen and (max-width: 991px) {
      display: block;
    }

    span {
      display: block;
      position: absolute;
      width: 26px;
      opacity: 1;
      left: 0;
      top: 7px;
      @include rotate(0);
      transition: all .3s ease-in-out;
      background-color: #212128;
      height: 3px;
      -webkit-transform-origin: left center;
      transform-origin: left center;

      &:first-child {
        top: 0;
        -webkit-transform-origin: left center;
        transform-origin: left center;
      }

      &:last-of-type {
        top: 14px;
      }
    }
  }
  .header-menu {
    @media screen and (max-width: 991px) {
      padding-top: 30px;
      padding-bottom: 30px;
      visibility: hidden;
      opacity: 0;
      z-index: 20;
      display: block;
      position: fixed;
      top: 65px;
      left: 0;
      width: 100%;
      height: calc(100vh - 65px);
      overflow: hidden;
      overflow-y: auto;
      margin-left: 0;
      margin-right: 0;
      background-color: $white;
      transition: all .4s ease-in-out;
    }
    /*  @media screen and (min-width: 480px) and (max-width: 991px) {
        width: calc(100% + 60px);
        left: -30px;
      }
      @media screen and (max-width: 479px) {
        width: calc(100% + 30px);
        left: -15px;
      }*/
    li {
      &.menu-item-has-children {
        &:hover {
          > .sub-menu {
            @media screen and (min-width: 992px) {
              opacity: 1;
              visibility: visible;
              //will-change: opacity;
              @include translate(0, 0);
            }
          }
          > a:after {
            opacity: 1;
          }
        }
        > a {
          &:after {
            opacity: 0;
            position: absolute;
            left: 50%;
            background-color: $brand;
            content: '';
            display: block;
            @include square(12px);
            @include transform(rotate(45deg) translateX(-50%));
            transition: all .35s ease-in-out;

            @media screen and (max-width: 991px) {
              display: none;
            }
          }
        }
      }
      a[href="#"] {
        cursor: default;
      }
    }
    > li {

      &.current-menu-item,
      &.current_page_item,
      &.current_page_parent,
      &.current-menu-ancestor,
      &.current-menu-parent,
      &:hover {
        > a {
          color: $brand;
        }
      }
      > a {
        position: relative;
        display: block;
        padding: 15px 15px 35px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: normal;
        @media screen and (min-width: 1200px) and (max-width: 1279px) {
          padding-left: 12px;
          padding-right: 12px;
          font-size: 17px;
        }
        @media screen and (min-width: 992px) and (max-width: 1199px) {
          padding-left: 7px;
          padding-right: 7px;
          font-size: 15px;
        }
        @media screen and (min-width: 480px) and (max-width: 991px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media screen and (max-width: 479px) {
          padding-left: 15px;
          padding-right: 15px;
        }
        &:after {
          //bottom: -26px;
          bottom: -11px;
        }
      }

      .sub-menu {
        @include flex(center, center, row);
        z-index: 3;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        @include translate(0, 0);
        visibility: hidden;
        opacity: 0;
        transition: opacity .5s ease-in-out;
        //padding-top: 32px;
        //padding-bottom: 32px;
        background-color: $brand;
        @media screen and (max-width: 991px) {
          padding-top: 15px;
          padding-bottom: 15px;
          display: none;
          position: static;
          @include transform(none);
          width: 100vw;
          opacity: 1;
          visibility: visible;
          transition: none;
        }
        li {
          padding-left: 25px;
          padding-right: 25px;
          .sub-menu {
            background-color: #eb6962;

            @media screen and (min-width: 480px) and (max-width: 991px) {
              width: calc(100vw - 30px);
            }
            @media screen and (max-width: 479px) {
              width: calc(100vw - 15px);
            }
          }
          > a {
            &:after {
              @media screen and (min-width: 992px) {
                opacity: 0;
                position: absolute;
                bottom: -26px;
                left: 50%;
                background-color: #eb6962;
                content: '';
                display: block;
                @include square(12px);
                @include transform(rotate(45deg) translateX(-50%));
                transition: all .35s ease-in-out;
              }
            }
          }
          @media screen and (max-width: 991px) {
            padding-top: 5px;
            padding-bottom: 5px;
          }
          &.current-menu-item,
          &.current_page_item,
          &.current_page_parent {
            > a {
              font-weight: 800;
            }
          }
        }
        a {
          position: relative;
          display: block;
          padding-top: 32px;
          padding-bottom: 32px;
          color: $white;
          @media screen and (max-width: 991px) {
            padding-top: 8px;
            padding-bottom: 8px;
          }
          &:after {
            bottom: -11px !important;
          }
        }
      }
    }
  }
}

/* Footer */
/* ---------------------------------- */

.site-footer {
  background-color: $white;
  h3, .h3 {
    margin-bottom: 10px;
    @media screen and (max-width: 479px) {
      text-align: center;
    }
  }
  .footer-banner {
    max-width: 1328px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 38px;
    color: $white;
    @media screen and (max-width: 991px) {
      display: block;
      margin-bottom: 65px;
    }
    > * {
      width: 50%;
      min-width: 50%;
      max-width: 50%;
      @include flexGrow(0 1 50%);
      padding: 24px 48px 32px;
      @media screen and (min-width: 480px) and (max-width: 1199px) {
        padding-left: 30px;
        padding-right: 30px;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        @include flexGrow(0 1 100%);
      }
      @media screen and (max-width: 479px) {
        padding: 42px 15px;
      }
    }
  }
  .banner-info {
    position: relative;
    background-color: $purple;
    .h3 {
      @media screen and (max-width: 479px) {
        position: absolute;
        top: 42px;
        left: 0;
        width: 100%;
      }
    }
    img {
      margin-top: -64px;
      margin-right: 16px;
      display: block;
      max-width: 112px;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
      @media screen and (max-width: 1199px) {
        max-width: 96px;
      }
      @media screen and (max-width: 479px) {
        margin-top: 42px;
      }
    }
    img + div {
      @include flexGrow(1);
      @media screen and (max-width: 479px) {
        margin-top: 42px;
      }
    }
  }
  .banner-newsletter {
    background-color: $pinkDark;
  }

  .footer-main {
    padding-bottom: 48px;
    @media screen and (max-width: 991px) {
      padding-bottom: 65px;
    }
    .container > .ctn-flex {
      margin-left: -40px;
      margin-right: -40px;
      @media screen and (max-width: 991px) {
        display: block;
        margin-left: 0;
        margin-right: 0;
      }
    }

  }

  .main-infos {
    margin-left: 40px;
    margin-right: 40px;

    @media screen and (max-width: 991px) {
      margin-bottom: 40px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
      @include justifyContent(space-between);
      @include alignItems(flex-end);
    }
    @media screen and (max-width: 991px) {
      margin-left: -15px;
      margin-right: -15px;
      > * {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
    @media screen and (max-width: 767px) {
      text-align: center;
    }

    > a {
      display: block;
      @media screen and (max-width: 991px) {
        width: 193px;
        min-width: 193px;
        max-width: 193px;
        @include flexGrow(0 1 193px);
      }
      @media screen and (max-width: 767px) {
        display: inline-block;
      }
    }

    svg:not(.ico) {
      margin-bottom: 26px;
      max-width: 200px;
      @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-bottom: 0;
        position: relative;
        top: 4px;
      }
    }
    p {
      &:not(:last-child) {
        @media screen and (min-width: 768px) and (max-width: 991px) {
          margin-bottom: 0;
        }
      }
      a {
        color: $black;
        font-weight: 800;
        .ico {
          margin-left: 8px;
          fill: $black;
        }
        &:hover {
          color: lighten($black, 20%);
          border-bottom: none;
          .ico {
            @include translateX(4px);
            fill: lighten($black, 20%);
          }
        }
      }
    }
  }

  .main-logos {
    @include flexGrow(1);
    margin-left: 40px;
    margin-right: 40px;

    @media screen and (max-width: 991px) {
      margin-left: -15px;
      margin-right: -15px;
      > * {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
    @media screen and (max-width: 767px) {
      text-align: center;
    }
    p {
      color: $purple;
      font-size: 14px;
      line-height: 1.71;
    }
    .logos-items {
      margin-top: 16px;
      @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-left: 8px;
        margin-right: 8px;
      }
      @media screen and (max-width: 767px) {
        @include justifyContent(center);
      }
    }
    .logo-item {
      display: inline-block;
      margin: 17px 8px;
      img {
        display: block;
        max-width: 180px;
        height: auto;
        max-height: 50px;
        width: auto;
      }
    }
  }

  .footer-menu-ctn {
    margin-top: 32px;
    @media screen and (max-width: 767px) {
      @include justifyContent(center);
    }
    > * {
      margin-left: 8px;
      margin-right: 8px;

      color: $pinkMedium;
      line-height: 1.71;
    }
    .footer-menu {
      @media screen and (max-width: 767px) {
        @include justifyContent(center);
      }
      a {
        display: block;
        margin-left: 8px;
        margin-right: 8px;
        &:hover {
          color: $pinkDark;
        }
      }
    }
  }
}

/* Commons */
/* ---------------------------------- */

.content-header {
  position: relative;
  overflow: hidden;
  text-align: center;
  &.content-header--infos {
    .header-inner {
      @include alignItems(flex-start);
    }
  }
  > .container {
    @media screen and (max-width: 991px) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .header-inner {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: 280px;
    //@include flex(center, center, row);
    display: table;
    width: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 991px) {
      min-height: inherit;
    }
  }
  .header-infos {
    margin-bottom: 16px;
    color: $white;
  }
  .inner-ctn {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    padding-left: 8px;
    padding-right: 8px;
    @media screen and (min-width: 992px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  h1 {
    color: $white;
  }
}

.content-intro {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;

  .bg-light {
    margin-top: -24px;
    padding: 40px 30px;
    text-align: left;
    @media screen and (min-width: 480px) and (max-width: 767px) {
      margin-left: -30px;
      margin-right: -30px;
    }
    @media screen and (max-width: 479px) {
      padding-left: 15px;
      padding-right: 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
    .container-mini {
      margin: 0;
      @media screen and (max-width: 991px) {
        max-width: 100%;
      }
    }
    .ctn-flex {
      margin-left: 0;
      margin-right: 0;
      @media screen and (max-width: 991px) {
        display: block;
        text-align: center;
      }
    }

    .btn {
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        margin-left: 30px;
      }
      @media screen and (max-width: 991px) {
        margin-top: 30px;
      }
      @media screen and (max-width: 1199px) {
        position: relative;
        padding-right: 50px;
        text-align: left;
        .ico {
          position: absolute;
          top: 50%;
          right: 20px;
          @include translateY(-50%);
        }
      }
    }
  }
}

.content-main {
  > .container {
    position: relative;
  }
}

.main-inner {
  padding-top: 50px;
}

.card-listing, .main-inner {
  padding-bottom: 100px;

  @media screen and (min-width: 480px) and (max-width: 1199px) {
    padding-bottom: 60px;
  }
  @media screen and (max-width: 479px) {
    padding-bottom: 50px;
  }
}

.card-listing {
  &.card-listing--mini {
    .card {
      width: 16.66%;
      min-width: 16.66%;
      max-width: 16.66%;
      @include flexGrow(0 1 16.66%);
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        width: 20%;
        min-width: 20%;
        max-width: 20%;
        @include flexGrow(0 1 20%);
      }
      @media screen and (min-width: 800px) and (max-width: 991px) {
        width: 25%;
        min-width: 25%;
        max-width: 25%;
        @include flexGrow(0 1 25%);
      }
      @media screen and (min-width: 640px) and (max-width: 799px) {
        width: 33.33%;
        min-width: 33.33%;
        max-width: 33.33%;
        @include flexGrow(0 1 33.33%);
      }
      @media screen and (min-width: 480px) and (max-width: 639px) {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
        @include flexGrow(0 1 50%);
      }
      @media screen and (max-width: 479px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        @include flexGrow(0 1 100%);
      }
    }
  }
}

a.card {
  cursor: pointer;
  @media screen and (min-width: 992px) {
    &:hover {
      .card-thumb {
        &:before {
          background-color: rgba($brand, .3);
        }
      }
      .card-title {
        color: $brand;
      }
      .card-link {
        color: $brand;
        .ico {
          @include translateX(4px);
          fill: $brand;
        }
      }
    }
  }
}

.card {
  width: 25%;
  min-width: 25%;
  max-width: 25%;
  @include flexGrow(0 1 25%);
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 24px;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 33.33%;
    min-width: 33.33%;
    max-width: 33.33%;
    @include flexGrow(0 1 33.33%);
  }
  @media screen and (min-width: 580px) and (max-width: 767px) {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    @include flexGrow(0 1 50%);
  }
  @media screen and (max-width: 579px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    @include flexGrow(0 1 100%);
    &:last-child {
      margin-bottom: 0;
    }
  }

  .card-thumb {
    display: block;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
    background-color: $light;
    &:before {
      position: relative;
      z-index: 1;
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 78.3%;
      transition: all .35s ease-in-out;
    }
    img {
      @include absCenter();
      display: block;
      width: auto;
      height: 100%;
    }

    .card-date, .card-infos {
      z-index: 1;
      margin: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $brand;
      padding: 9px 12px 8px;
      color: $white;
      font-size: 12px;
      font-weight: 800;
      text-transform: uppercase;
    }

    .card-title {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 100%);
      z-index: 1;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 25px 22px;
      color: $white;
    }
  }

  .card-date, .card-infos {
    display: block;
    margin-bottom: 8px;
    color: $grey;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.23;
    span {
      color: $brand;
      text-transform: uppercase;
      font-weight: 800;
    }
  }

  .card-title {
    margin-bottom: 0;
    transition: all .35s ease-in-out .1s;
  }

  .card-content {
    margin-top: 8px;
  }

  .card-link {
    margin-top: 24px;
    font-weight: 800;
    .ico {
      margin-left: 8px;
      fill: $black;
    }
  }

  .media-play {
    @include square(60px);
    .ico {
      width: 8px;
      height: 13px;
    }
  }

}

.bg-light {
  background-color: $light;
}

.tag {
  margin-bottom: 15px;
  font-weight: 800;
  text-transform: uppercase;
}

.btn-back {
  position: absolute;
  top: 50px;
  left: 30px;
  display: inline-block;
  color: $brand;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  text-transform: uppercase;
  @media screen and (max-width: 991px) {
    position: static;
    margin-top: 30px;
    br {
      display: none;
    }
  }
  .ico {
    display: block;
    margin-bottom: 12px;
    @media screen and (max-width: 991px) {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 12px;
    }
  }
}

.media-play {
  z-index: 1;
  cursor: pointer;
  @include flex(center, center, row);
  @include absCenter();
  @include square(86px);
  border-radius: 50%;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
  background-color: rgba($brand2, 0.9);
  transition: all .35s ease-in-out;
  @media screen and (max-width: 767px) {
    @include square(60px);
  }
  &:hover {
    background-color: $white;
    .ico {
      fill: $brand;
    }
  }
}

/* Search */
/* ---------------------------------- */

.search-results {
  padding-top: 12px;
  padding-bottom: 36px;
  text-align: center;
  span {
    color: $brand;
    font-style: italic;
  }
}

/* Edito */
/* ---------------------------------- */

.site-content--default {
  .bloc {
    h3 {
      margin-bottom: 15px;
      font-weight: 800;
      font-size: 16px;
    }
  }
  .bloc-cols {
    margin-top: 85px;
    //padding-bottom: 35px;
    &.bg-light {
      .layout {
        &.ctn-flex-row-reverse {
          .layout-aside {
            @media screen and (max-width: 479px) {
              margin-top: -70px;
            }
          }
        }
      }
    }
    &:not(:last-child) {
      @media screen and (max-width: 991px) {
        margin-top: 70px;
      }
    }
    .cols-media {
      &:before {
        display: none;
      }
      img {
        position: static;
        @include transform(none);
      }
    }
    .media-caption {
      position: static;
      margin-top: 16px;
      color: #999;
      font-size: 13px;
      text-transform: none;
      text-shadow: none;
    }

    .layout {
      /*    @media screen and (max-width: 899px) {
            display: block;
          }*/

      @media screen and (max-width: 899px) {
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Chrome */
        display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
        @include flexDirection(column);
        > * {
          @include flexGrow(1 !important);
        }
      }

      &.layout--2-2 {
        margin-left: 0;
        margin-right: 0;

        &.layout--push:not(.ctn-flex-row-reverse) {
          .layout-main {
            > * {
              @media screen and (min-width: 900px) and (max-width: 991px) {
                margin-right: 30px;
              }
            }
          }
          .layout-aside {
            @media screen and (max-width: 899px) {
              margin-top: 35px;
              margin-bottom: 0;
            }
          }
        }
        &.ctn-flex-row-reverse {
          @media screen and (max-width: 899px) {
            @include flexDirection(column-reverse);
            > * {
              @include flexGrow(1 !important);
            }
          }
          .layout-main {
            > * {
              @media screen and (min-width: 900px) and (max-width: 991px) {
                margin-left: 30px;
              }
            }
          }
        }
        &.layout--push,
        &.ctn-flex-row-reverse {
          .layout-aside {
            width: 400px;
            min-width: 400px;
            max-width: 400px;
            @include flexGrow(0 1 400px);
            @media screen and (max-width: 899px) {
              margin: 0 auto 70px;
              width: auto;
              min-width: inherit;
              max-width: 400px;
              @include flexGrow(0 1 100%);
            }

          }
          .layout-main {
            @include flexGrow(1);
            width: inherit;
            max-width: inherit;
            min-width: inherit;
            @media screen and (max-width: 899px) {
              margin-bottom: 0;
            }
            > * {
              max-width: 100%;
            }
          }
        }
        .layout-main {
          margin-left: 0;
          margin-right: 0;
          width: 400px;
          min-width: 400px;
          max-width: 400px;
          @include flexGrow(0 1 400px);
          @media screen and (max-width: 899px) {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            @include flexGrow(0 1 100%);
          }
          > * {
            max-width: 300px;
            @media screen and (max-width: 899px) {
              max-width: 100%;
            }
          }
        }
        .layout-aside {
          margin-left: 0;
          margin-right: 0;
          /*     width: calc(60%);
               min-width: calc(60%);
               max-width: calc(60%);
               @include flexGrow(0 1 calc(60%));*/
          @include flexGrow(1);
          width: inherit;
          max-width: inherit;
          min-width: inherit;

          @media screen and (max-width: 899px) {
            margin-top: 35px;
            width: auto;
            min-width: inherit;
            max-width: 610px;
            @include flexGrow(0 1 100%);
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &:not(.bg-light) {
      .cols-inner {
        > .ctn-flex {
          @include alignItems(flex-start);
        }
      }
    }
  }
  .bloc-simple {
    margin-bottom: 85px;
    @media screen and (max-width: 991px) {
      margin-bottom: 70px;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 85px;
      @media screen and (max-width: 991px) {
        padding-bottom: 70px;
      }
    }
    &:first-child {
      margin-bottom: 0;
      & + .bloc-simple.bg-light,
      & + .bloc-cols {
        margin-top: 30px;
      }
    }
  }

  .bg-light + .bg-light {
    margin-top: 0;
  }

  .bloc-cols + .bloc-simple.bg-light {
    padding-top: 145px;
    padding-bottom: 110px;
    margin-top: -70px;
    @media screen and (max-width: 991px) {
      margin-top: 70px;
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
  .bloc-cols + .bloc-simple {
    margin-top: 85px;
    @media screen and (max-width: 991px) {
      margin-top: 70px;
    }
  }

  .bloc-simple + .bloc-simple {
    margin-top: 85px;
    @media screen and (max-width: 991px) {
      margin-top: 70px;
    }
  }
  .bloc-simple + .bloc-simple.bg-light {
    margin-top: 85px;
    @media screen and (max-width: 991px) {
      margin-top: 70px;
    }
  }
  .bloc-simple.bg-light + .bloc-simple {
    margin-top: 85px;
    @media screen and (max-width: 991px) {
      margin-top: 70px;
    }
  }

  .bloc-simple.bg-light + .bloc-cols.bloc-cols--push {
    margin-top: 210px;
    @media screen and (max-width: 991px) {
      margin-top: 70px;
    }
  }
  .bloc-simple:not(.bg-light) + .bloc-cols.bloc-cols--push {
    margin-top: 60px;
    @media screen and (max-width: 991px) {
      margin-top: 30px;
    }
  }

  .bloc-simple:not(.bg-light) + .bloc-cols:not(.bg-light) {
    margin-top: 85px;
    @media screen and (max-width: 991px) {
      margin-top: 70px;
    }
  }

  .bloc-simple.bg-light + .bloc-cols:not(.bg-light):not(.bloc-cols--push) {
    margin-top: 85px;
    @media screen and (max-width: 991px) {
      margin-top: 70px;
    }
  }

}

.bloc-simple {
  &:first-child {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &.bg-light {
    padding-top: 70px;
    padding-bottom: 70px;
    @media screen and (max-width: 991px) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
  h2 {
    margin-bottom: 24px;
  }
}

.bloc-cols {

  &.bg-light {
    .cols-inner {
      padding-top: 85px;
      padding-bottom: 85px;
      //padding-top: 40px;
      //padding-bottom: 40px;
      /*  @media screen and (max-width: 899px) {
          padding-top: 0;
          padding-bottom: 0;
        }*/
      @media screen and (max-width: 991px) {
        padding-top: 70px;
        padding-bottom: 70px;
      }
      //@media screen and (max-width: 479px) {
      //  padding-top: 40px;
      //  padding-bottom: 40px;
      //}
    }
  }
  &:last-child {
    .cols-inner {
      padding-bottom: 100px;
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        padding-bottom: 85px;
      }
      @media screen and (min-width: 480px) and (max-width: 991px) {
        padding-bottom: 85px;
      }
      @media screen and (max-width: 479px) {
        padding-bottom: 40px;
      }
    }
  }
  .cols-inner {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    /*   padding-top: 40px;
       padding-bottom: 40px;
       @media screen and (max-width: 991px) {
         padding-top: 0;
         padding-bottom: 0;
       }*/
  }

  .layout {

    @media screen and (min-width: 800px) and (max-width: 991px) {
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    }

    &.layout--2-2 {
      &.layout--push {
        .layout-aside {
          margin-top: -120px;
          @media screen and (max-width: 991px) {
            margin-top: 0;
          }
        }
      }
      &.layout--push:not(.ctn-flex-row-reverse) {
        .layout-main {
          > * {
            margin-right: 105px;
            @media screen and (min-width: 992px) and (max-width: 1199px) {
              margin-right: 30px;
            }
            @media screen and (max-width: 991px) {
              margin-right: 0;
            }
          }
        }

      }
      &.ctn-flex-row-reverse {
        .layout-main {
          > * {
            margin-left: 105px;
            @media screen and (min-width: 992px) and (max-width: 1199px) {
              margin-left: 30px;
            }
            @media screen and (max-width: 991px) {
              margin-left: 0;
            }
          }
        }
      }
      &.layout--push,
      &.ctn-flex-row-reverse {
        .layout-main {
          @media screen and (min-width: 800px) and (max-width: 991px) {
            width: calc(55% - 30px);
            min-width: calc(55% - 30px);
            max-width: calc(55% - 30px);
            @include flexGrow(0 1 calc(55% - 30px));
          }
          @media screen and (max-width: 799px) {
            @include flexGrow(1);
            width: calc(100% - 30px);
            min-width: calc(100% - 30px);
            max-width: calc(100% - 30px);
          }
        }
        .layout-aside {
          @media screen and (min-width: 800px) and (max-width: 991px) {
            width: calc(45% - 30px);
            min-width: calc(45% - 30px);
            max-width: calc(45% - 30px);
            @include flexGrow(0 1 calc(45% - 30px));
          }
          @media screen and (max-width: 799px) {
            @include flexGrow(1);
            width: calc(100% - 30px);
            min-width: calc(100% - 30px);
            max-width: calc(100% - 30px);
          }
        }

      }

      .layout-main {
        @media screen and (min-width: 800px) and (max-width: 991px) {
          width: calc(55% - 30px);
          min-width: calc(55% - 30px);
          max-width: calc(55% - 30px);
          @include flexGrow(0 1 calc(55% - 30px));
        }
        @media screen and (max-width: 799px) {
          @include flexGrow(1);
          width: calc(100% - 30px);
          min-width: calc(100% - 30px);
          max-width: calc(100% - 30px);
        }
      }
      .layout-aside {
        @media screen and (min-width: 800px) and (max-width: 991px) {
          width: calc(45% - 30px);
          min-width: calc(45% - 30px);
          max-width: calc(45% - 30px);
          @include flexGrow(0 1 calc(45% - 30px));
        }
        @media screen and (max-width: 799px) {
          @include flexGrow(1);
          width: calc(100% - 30px);
          min-width: calc(100% - 30px);
          max-width: calc(100% - 30px);
        }
      }
    }

  }

  h2 {
    max-width: 505px;
    margin-bottom: 24px;
  }
  p {
    max-width: 400px;
  }
  a {
    margin-top: 24px;
  }

  .cols-media {
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 479px) {
      margin-left: -15px;
      margin-right: -15px;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 67%;
    }
    img, iframe {
      @include absCenter();
      display: block;
      width: 100% !important;
      height: auto;
    }
  }

  .media-caption {
    position: absolute;
    bottom: 9px;
    left: 16px;
    width: calc(100% - 32px);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    color: $white;
    font-weight: 800;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      font-size: 13px;
    }
    @media screen and (max-width: 479px) {
      margin-left: 15px;
    }
  }

  .push {
    position: relative;
    padding: 60px 50px;
    background-color: $pinkDark;
    color: $white;
    line-height: 1.29;
    font-size: 14px;

    @media screen and (min-width: 480px) and (max-width: 991px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media screen and (max-width: 479px) {
      margin-left: -15px;
      margin-right: -15px;
      padding: 50px 15px 45px;
    }
    a {
      border-bottom: 1px solid $white;
      &:hover {
        border-bottom-color: transparent;
      }
    }
    strong {
      color: $white;
    }
    &:first-child {
      //margin-top: -75px;
      background-color: $purple;

      //@media screen and (max-width: 991px) {
      //  margin-top: 0;
      //}
    }
    .push-title {
      @include flexGrow(1);
      margin-bottom: 15px;
      color: $brand2;
      @media screen and (max-width: 991px) {
        padding-right: 100px;
      }
      span {
        color: $white;
      }
    }

    img {
      margin-left: 20px;
      display: block;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      @include flexGrow(0 1 100px);
      @media screen and (max-width: 991px) {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }
}

/* Home */
/* ---------------------------------- */

.home-slider {
  color: $white;
  margin-bottom: 40px;
  .container {
    position: relative;
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
  .slide {
    margin-left: 8px;
    margin-right: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      margin-left: 4px;
      margin-right: 4px;
    }
    @media screen and (max-width: 991px) {
      margin-left: 0;
      margin-right: 0;
    }
    @media screen and (max-width: 767px) {
      background-position: top left;
      background-size: auto 240px;
      background-color: #a4173b;
    }

  }
  a.slide {
    @media screen and (max-width: 767px) {
      background-position: center;
      background-size: auto 100%;
      background-color: $white;
    }
  }
  .slider-inner-ctn {
    //@include flex(flex-start, center, row);
    display: table;
    min-height: 345px;
    margin-left: 45%;

    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin-left: 260px;
    }
    @media screen and (max-width: 767px) {
      display: block;
      padding-top: 300px;
      padding-bottom: 60px;
      margin-left: 30px;
      margin-right: 30px;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      margin-left: 30px;
      margin-right: 30px;
    }
    @media screen and (max-width: 479px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .slide-inner {
    max-width: 380px;
    display: table-cell;
    vertical-align: middle;
  }
  .slide-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 1.2;
  }
  .slide-content {
    margin-top: 10px;
    color: $white;
  }
  .slide-link {
    display: block;
    margin-top: 24px;
    font-size: 13px;
    font-weight: 800;
    text-transform: uppercase;
    .ico {
      margin-left: 10px;
    }

    &:hover {
      color: darken($white, 20%);
      .ico {
        @include translateX(4px);
        fill: darken($white, 20%);
      }
    }
  }

  .slider-arrows {
    visibility: hidden;
    position: absolute;
    width: calc(100% - 60px);
    top: 50%;
    @include translateY(-50%);
    @media screen and (max-width: 767px) {
      top: 34%;
      width: 100%;
    }

    > * {
      visibility: visible;
      cursor: pointer;
      @include flex(center, center, row);
      @include square(56px);
      background-color: $brand;
      transition: all .35s ease-in-out;
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        @include square(42px);
      }
      &:hover {
        background-color: darken($brand, 10%);
      }
    }
    .arrow-left {
      @include scaleX(-1);
    }
    .arrow-right {
      margin-left: auto;
      margin-top: -56px;
    }
  }
}

.home-news-bao {
  margin-bottom: 55px;
  @media screen and (max-width: 991px) {
    &.ctn-flex.ctn-flex-mini {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
  > * {
    margin-left: 8px;
    margin-right: 8px;
    @media screen and (max-width: 991px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.home-news {
  width: calc(75% - 16px);
  min-width: calc(75% - 16px);
  max-width: calc(75% - 16px);
  @include flexGrow(0 1 calc(75% - 16px));
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    width: inherit;
    min-width: inherit;
    max-width: inherit;
    @include flexGrow(1);
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    @include flexGrow(0 1 100%);
  }
  h2 {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 32px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      font-size: 32px;
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
      text-align: center;
    }
  }
  .card-listing {
    padding-bottom: 0;
  }
  .card {
    width: 33.33%;
    min-width: 33.33%;
    max-width: 33.33%;
    @include flexGrow(0 1 33.33%);
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      width: 50%;
      min-width: 50%;
      max-width: 50%;
      @include flexGrow(0 1 50%);
    }
    @media screen and (min-width: 680px) and (max-width: 991px) {
      width: 50%;
      min-width: 50%;
      max-width: 50%;
      @include flexGrow(0 1 50%);
    }
    @media screen and (max-width: 679px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      @include flexGrow(0 1 100%);
    }
  }
}

.home-bao {
  margin-top: 64px;
  padding: 30px 20px;
  width: calc(25% - 16px);
  min-width: calc(25% - 16px);
  max-width: calc(25% - 16px);
  @include flexGrow(0 1 calc(25% - 16px));
  background-repeat: no-repeat;
  background-position: bottom 30px right 30px;
  background-color: $purple;
  color: $white;
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    width: calc(33.33% - 8px);
    min-width: calc(33.33% - 8px);
    max-width: calc(33.33% - 8px);
    @include flexGrow(0 1 calc(33.33% - 8px));
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    @include flexGrow(0 1 100%);
  }
  @media screen and (max-width: 767px) {
    margin-top: 35px;
    background-image: none !important;
  }
  h2 {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 32px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      font-size: 32px;
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }
  h2, p {
    text-align: center;
  }
  p {
    font-size: 14px;
  }

  .bao-items {
    margin-top: 45px;
  }
  .bao-item {
    margin-bottom: 28px;
    @include flex(flex-start, flex-start, row);

    &:hover {
      .item-ico {
        background-color: $white;
        .ico {
          fill: $brand;
        }
      }
      .item-content {
        .ico {
          @include translateX(4px);
          fill: $white;
        }
      }
    }

    .item-ico {
      min-width: 36px;
      max-width: 36px;
      @include flexGrow(0 1 36px);
      @include square(36px);
      @include flex(center, center, row);
      margin-right: 16px;
      background-color: $brand2;
      border-radius: 50%;
      transition: all .35s ease-in-out;
      span {
        display: block;
        @include square(18px);
        background-size: contain;
        background-position: center;
      }
    }

    .item-content {
      color: $white;
      font-size: 16px;
      font-weight: 800;
      line-height: 1.13;
      text-transform: uppercase;
      span {
        display: block;
        margin-top: 5px;
        color: $brand;
        font-size: 13px;
      }
      .ico {
        fill: $brand;
      }
    }
  }
}

.home-actions {
  margin-bottom: 42px;
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
  .container {
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
  .actions-inner {
    padding: 55px 38px 55px 300px;
    background-color: $pink;
    background-repeat: no-repeat;
    background-position: top left;
    @media screen and (min-width: 992px) and (max-width: 1139px) {
      padding-left: 100px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding-left: 38px;
    }
    @media screen and (min-width: 480px) and (max-width: 767px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 50px;
      padding-bottom: 75px;
    }
    @media screen and (max-width: 479px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  h2, h4 {
    color: $white;
  }
  h2 {
    margin-bottom: 40px;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 32px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      font-size: 32px;
    }
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }
  .actions-items {
    margin-top: 10px;
    margin-left: -1px;
    margin-right: -1px;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      @include flexWrap(wrap);
    }
    @media screen and (max-width: 767px) {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .action-item {
    position: relative;
    @include flexGrow(1);
    margin-left: 1px;
    margin-right: 1px;
    padding: 24px 20px 16px;
    border-radius: 2px;
    background-color: $white;

    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin: 1px;
    }
    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
      margin-top: 1px;
      margin-bottom: 1px;
    }

    @for $i from 0 through 100 {
      &.action-item-#{$i} {
        &:before {
          content: '';
          display: block;
          position: absolute;
          //right: 0;
          left: 0;
          top: 0;
          //width: calc(100% - (#{$i} * 1%));
          width: calc((#{$i} * 1%));
          height: 100%;

          background-color: rgba($pink, .3);
        }
      }
    }

    .item-name {
      position: relative;
      z-index: 1;
      margin-bottom: 5px;
      color: $purple;
      font-size: 14px;
      font-weight: 800;
      line-height: 1.14;
      text-transform: uppercase;
    }
    .item-date {
      position: relative;
      z-index: 1;
      color: $greyMedium;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.home-about {
  .bloc {
    margin-bottom: 0;
  }
  .bloc-cols {
    .cols-inner {
      max-width: inherit;
      margin-left: 0;
      margin-right: 0;
      padding-top: 98px;
      padding-bottom: 90px;
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        padding-top: 55px;
      }
      @media screen and (max-width: 991px) {
        padding-top: 75px;
      }
      @media screen and (min-width: 480px) and (max-width: 991px) {
        padding-bottom: 75px;
      }
      @media screen and (max-width: 479px) {
        padding-bottom: 40px;
      }
      .layout.layout--2-2 {
        .layout-main {
          @media screen and (max-width: 767px) {
            margin-bottom: 50px;
          }
        }
      }
      /*      .layout {
              @media screen and (min-width: 800px) and (max-width: 991px) {
                display: -webkit-box; !* OLD - iOS 6-, Safari 3.1-6 *!
                display: -moz-box; !* OLD - Firefox 19- (buggy but mostly works) *!
                display: -ms-flexbox; !* TWEENER - IE 10 *!
                display: -webkit-flex; !* NEW - Chrome *!
                display: flex; !* NEW, Spec - Opera 12.1, Firefox 20+ *!
              }
              &.layout--2-2 {
                .layout-main {
                  @media screen and (min-width: 800px) and (max-width: 991px) {
                    width: calc(55% - 30px);
                    min-width: calc(55% - 30px);
                    max-width: calc(55% - 30px);
                    @include flexGrow(0 1 calc(55% - 30px));
                  }
                }
                .layout-aside {
                  @media screen and (min-width: 800px) and (max-width: 991px) {
                    width: calc(45% - 30px);
                    min-width: calc(45% - 30px);
                    max-width: calc(45% - 30px);
                    @include flexGrow(0 1 calc(45% - 30px));
                  }
                }
              }
            }*/
    }
    h2 {
      margin-bottom: 46px;
    }
    .cols-media {
      &:before {
        padding-bottom: 88%;
      }
    }
  }

}

/* SAS Documents */
/* ---------------------------------- */

.site-content--documents {
  #document_list_items .card-item {
    -webkit-transition: opacity 0.15s ease;
    -moz-transition: opacity 0.15s ease;
    -ms-transition: opacity 0.15s ease;
    -o-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
    &.hide {
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }
  }
  .card {
    margin-bottom: 40px;
    @media screen and (max-width: 579px) {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .card-thumb {
      img {
        height: 90%;
      }
    }
  }
}

/* SAS Products */
/* ---------------------------------- */

.site-content--products {
  h2 {
    margin-top: 35px;
    margin-bottom: 20px;
  }
  .card {
    margin-bottom: 40px;
    .card-thumb {
      background-color: #c7c7c7;
      &:before {
        padding-bottom: 50%;
      }
    }
    .card-title {
      background: none;
      color: $black;
    }
    &:hover {
      .card-link {
        .ico {
          @include transform(translateY(4px) rotate(90deg));
        }
      }
    }
    .card-link {
      .ico {
        @include rotate(90deg);
      }
    }
  }
}

.products-list-lexical {
  > * {
    margin-left: 8px;
    margin-right: 8px;
  }

  .products {
    width: calc(75% - 16px);
    min-width: calc(75% - 16px);
    max-width: calc(75% - 16px);
    @include flexGrow(0 1 calc(75% - 16px));
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      width: inherit;
      min-width: inherit;
      max-width: inherit;
      @include flexGrow(1);
    }
    @media screen and (max-width: 991px) {
      width: calc(100% - 16px);
      min-width: calc(100% - 16px);
      max-width: calc(100% - 16px);
      @include flexGrow(0 1 calc(100% - 16px));
    }
    .card-listing {
      @media screen and (max-width: 991px) {
        padding-bottom: 0;
      }
    }
    .card {
      width: 33.33%;
      min-width: 33.33%;
      max-width: 33.33%;
      @include flexGrow(0 1 33.33%);
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
        @include flexGrow(0 1 50%);
      }
      @media screen and (min-width: 680px) and (max-width: 991px) {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
        @include flexGrow(0 1 50%);
      }
      @media screen and (max-width: 679px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        @include flexGrow(0 1 100%);
      }
    }
  }
  .lexical {
    margin-top: 24px;
    width: calc(25% - 16px);
    min-width: calc(25% - 16px);
    max-width: calc(25% - 16px);
    @include flexGrow(0 1 calc(25% - 16px));
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      width: calc(33.33% - 8px);
      min-width: calc(33.33% - 8px);
      max-width: calc(33.33% - 8px);
      @include flexGrow(0 1 calc(33.33% - 8px));
    }
    @media screen and (max-width: 991px) {
      margin-top: 0;
      width: calc(100% - 16px);
      min-width: calc(100% - 16px);
      max-width: calc(100% - 16px);
      @include flexGrow(0 1 calc(100% - 16px));
      margin-bottom: 60px;
    }

    .content-aside-inner {
      padding: 30px;
      background-color: $purple;
      color: $white;
      font-size: 14px;
      h4 {
        margin-bottom: 20px;
      }
      h4, span, em {
        color: $brand2;
      }
      em {
        font-style: italic;
      }
      p:last-child {
        font-size: 12px;
      }
    }
  }
}

/* SAS Testimonies */
/* ---------------------------------- */

.site-content--testimonies {
  .card {
    .card-date, .card-infos {
      text-transform: uppercase;
    }
  }
}

/* Single jobs */
/* ---------------------------------- */

.site-content--jobs-single {
  #form_container_area, h2:not(:first-child) {
    margin-top: 80px;
  }
  h2:first-child {
    margin-bottom: 30px;
  }
}

/* Single tests */
/* ---------------------------------- */

.site-content--tests-single {
  .test-ctn {
    text-align: center;
    position: absolute;
    opacity: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    width: 100%;
    visibility: hidden;
    &.current {
      position: relative;
      visibility: visible;
      opacity: 1;
      left: auto;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  .test-answered {
    &.test-answered--good {
      button {
        &.answer {
          background-color: $brand2;
        }
      }
      .test-answer.success {
        display: block;
      }
    }
    &.test-answered--wrong {
      button {
        background-color: rgba(216, 0, 52, 1);
        &.answer {
          background-color: #d7d7d7;
        }
      }
      .test-answer.error {
        display: block;
      }
    }
    button {
      pointer-events: none;
      background-color: #d7d7d7;

    }

    .test-footer {
      a {
        pointer-events: initial;
        color: $brand;
        .ico {
          fill: $brand;
        }
        &:hover {
          .ico {
            @include translateX(4px);
          }
        }
      }
    }
  }
  .test-steps {
    margin-bottom: 6px;
    color: #9b9b9b;
    font-weight: 800;
    line-height: 2;
    span {
      color: $brand;
    }

  }
  h2 {
    margin-bottom: 50px;
  }
  .test-choices {
    margin-bottom: 50px;
  }

  button {
    pointer-events: initial;
    cursor: pointer;
    padding: 21px 30px 19px;
    min-width: 135px;
    background-color: #212128;
    color: $white;
    font-size: 13px;
    font-weight: 800;
    line-height: 1.23;
    text-transform: uppercase;
    @media screen and (max-width: 479px) {
      min-width: 100%;
      margin: 2px 0;
    }
  }
  .test-answer {
    display: none;
    margin-bottom: 50px;
  }
  h4 {
    margin-bottom: 8px;
    color: $black;
  }
  .test-footer {
    @media screen and (max-width: 479px) {
      display: block;
    }
    > * {
      margin-left: 15px;
      margin-right: 15px;
    }
    div:first-child {
      width: 163px;
    }
    a {
      //pointer-events: none;
      color: #9b9b9b;
      font-size: 13px;
      font-weight: 800;
      line-height: 1.23;
      text-transform: uppercase;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease-out;
      -moz-transition: opacity 0.3s ease-out;
      -o-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      &.active {
        opacity: 1;
      }
      &.resume-link {
        display: none;
        &.active {
          display: block;
        }
      }
      .ico {
        margin-left: 10px;
        fill: #9b9b9b;
      }
    }
  }
  .footer-dots {
    margin-left: -6px;
    margin-right: -6px;
    @include justifyContent(center);
    @media screen and (max-width: 479px) {
      margin-bottom: 20px;
    }
    span {
      position: relative;
      display: inline-block;
      margin-left: 6px;
      margin-right: 6px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #d7d7d7;

      &.active {
        width: 16px;
        background-color: transparent;
        background-image: url('#{$imgPath}/dots-active.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

/* Template team */
/* ---------------------------------- */

.site-content--team {
  .main-inner {
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    h2 {
      margin-bottom: 15px;
    }
  }
  .card-listing:nth-child(2) {
    padding-bottom: 30px;
  }
  .card {
    .card-thumb {
      &:before {
        padding-bottom: 116%;
      }
    }
    .card-title {
      font-size: 16px;
      font-weight: 800;
      text-transform: uppercase;
    }
    .card-content {
      color: $grey;
      font-size: 13px;
      line-height: 1.23;
    }
  }

  .bloc-cols {
    .cols-inner {
      max-width: inherit;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

/* Contact */
/* ---------------------------------- */

.site-content--contact {
  .acf-map {
    background-color: $light;
    margin-bottom: 47px;
    height: 245px;
  }
  .layout-aside {
    h3 {
      margin-bottom: 5px;
    }
    p {
      color: #373e48;
      font-size: 14px;
      line-height: 1.43;
      font-weight: 300;
    }
  }
}

/* FAQ */
/* ---------------------------------- */

.site-content--faq {

  .layout-aside {
    @media screen and (max-width: 991px) {
      margin-bottom: 50px;
    }
    .content-aside-inner {
      padding: 45px 50px;
      background-color: $light;

      @media screen and (max-width: 479px) {
        padding: 30px 20px;
      }
    }

    a {
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d7d7d7;
      color: $black;
      font-size: 18px;
      /*   &:last-of-type {
           padding-bottom: 0;
         }*/
      &.active {
        font-weight: 800;
      }
    }
  }

  .faq-group {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
    h2 {
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 28px;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: 28px;
      }
      @media screen and (max-width: 767px) {
        font-size: 25px;
      }
    }
    h3 {
      font-size: 21px;
    }
  }
}

/* Register */
/* ---------------------------------- */

.site-content--register {
  .bloc-simple {
    &.bg-light {
      padding-top: 85px;
      padding-bottom: 85px;
      @media screen and (max-width: 991px) {
        padding-top: 55px;
        padding-bottom: 55px;
      }
    }
  }

  .main-inner {
    @media screen and (max-width: 991px) {
      padding-top: 0;
    }
    > .container {
      margin-top: 90px;
      @media screen and (max-width: 991px) {
        margin-top: 60px;
      }
    }
  }

  .layout-aside {
    @media screen and (max-width: 991px) {
      margin-bottom: 40px;
    }
    h2 {
      margin-bottom: 35px;
    }
  }
}

/* Slider With Modal */
/* ---------------------------------- */
.modal-content {
  img {
    max-height: 80vh;
    max-width: 100%;
    flex: 0 0 auto; /* IE */
    object-fit: scale-down; /* FF */
  }
}

#modal-gallery,
.content-slider {
  .slider-arrows {
    visibility: hidden;
    position: absolute;
    width: calc(100% - 60px);
    top: 50%;
    transform: translateY(-50%);
    .arrow-left {
      transform: scaleX(-1);
    }
    .arrow-right {
      margin-left: calc(100% + 5px);
      margin-top: -56px;
    }
    > * {
      visibility: visible;
      cursor: pointer;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-direction: row;
      flex-direction: row;
      width: 56px;
      height: 56px;
      background-color: #e63329;
      transition: all .35s ease-in-out;
    }
    .slick-arrow {
      display: -ms-flexbox;
      display: flex;
      &.slick-hidden {
        display: none;
      }
    }
  }
  .image {

    &[data-bg] {
      position: relative;
      overflow: hidden;
      margin-left: 8px;
      margin-right: 8px;
      background-position: center;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 75%;
      }
      img {
        @include absCenter();
        width: 100%;
        height: auto;
        left: -9999999px;
      }
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .thumb-caption {
    margin-top: 20px;
    text-align: center;
    color: $white;
    &.hidden {
      display: none;
    }
  }
}

.content-slider .slider-nav .image {
  cursor: pointer;
  display: none;
}

.content-slider .slider-nav.slick-initialized .image {
  display: block;
}

.content-slider .slider-nav .image img {
  width: 100%;
  max-width: 100%;
}

.modal-image-content {
  //max-width: 100%;
  width: auto;
  max-height: 80vh;
}

.content-slider {
  position: relative;
  margin: 15px 0;
}

.content-slider .slick-track {
  float: left;
}

.modal-content-area .modal-body {
  text-align: center;
}

/* Template towns */
/* ---------------------------------- */

.site-content--towns {

  .select2-container--default .select2-selection--single .select2-selection__clear {
    display: none;
  }

  .content-intro {
    margin-top: 40px;
    margin-bottom: 0;
    padding-bottom: 60px;

    h2 {
      margin-bottom: 25px;
    }
  }

  button[type="submit"] {
    margin-top: 0;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    @include flex(space-between, center, row);
  }

  form {
    margin-top: 45px;
    max-width: 608px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-row {
    .form-group {
      &:first-of-type {
        @include flexGrow(2);
      }
    }
  }

  .form-filters {
    margin-top: 20px;
    .container-medium {
      padding-top: 12px;
      border-top: 1px solid #d8d8d8;
      > .ctn-flex {
        @media screen and (max-width: 767px) {
          display: block;
        }
      }
    }
  }

  .filters-tags {
    margin-left: 12px;
    margin-right: 12px;
    > * {
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .filters-remove {
    display: none;
    margin-left: 15px;
    margin-right: 15px;
    color: $black;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Avenir', sans-serif;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
    &:hover {
      color: $brand;
    }
  }

  .tags-item {
    padding: 11px 15px 10px;
    border-radius: 2px;
    background-color: #d8d8d8;
    color: $black;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .content-main {
    padding-bottom: 120px;
    @media screen and (max-width: 767px) {
      padding-bottom: 70px;
    }
  }
}

.products-list-lexical .card-item.hide {
  //opacity: 0;
  //visibility: hidden;
  display: none;
}

.town-item {
  display: none;
  padding-bottom: 45px;
  border-bottom: 1px solid #d8d8d8;
  transition: all .35s ease-in-out;

  &:not(:first-of-type) {
    margin-top: 40px;
  }

  > .ctn-flex {
    @media screen and (max-width: 1059px) {
      display: block;
    }
  }

  .item-name {
    @include flexGrow(1);
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 8px;
    @media screen and (max-width: 1059px) {
      margin-bottom: 30px;
    }
    .name-small {
      color: $brand;
      font-size: 13px;
      line-height: 1.85;
    }
    h3 {
      margin-bottom: 0;
    }
  }
  .name-ico {
    position: relative;
    border-radius: 50%;
    @include square(64px);
    margin-right: 40px;
    background-color: $brand;

    @media screen and (max-width: 479px) {
      @include square(40px);
      margin-right: 20px;
    }
    .ico {
      @include absCenter();
      fill: $white;
      width: 22px;
      height: auto;
      @media screen and (max-width: 479px) {
        width: 15px;
      }
    }
  }

  .item-infos {
    margin-left: 15px;
    margin-right: 15px;
    width: 608px;
    min-width: 608px;
    max-width: 608px;
    @include flexGrow(0 1 608px);
    padding: 40px;
    background-color: $light;

    @media screen and (max-width: 1059px) {
      width: calc(100% - 30px);
      min-width: inherit;
      max-width: inherit;
      @include flexGrow(1);
    }
    @media screen and (max-width: 479px) {
      padding: 30px 20px;
    }
  }

  .infos-nb {
    margin-bottom: 35px;
    padding-bottom: 30px;
    border-bottom: 1px solid #d8d8d8;
    color: $brand;
    font-size: 16px;
    font-weight: 900;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1px;
    @media screen and (max-width: 479px) {
      padding-bottom: 20px;
      margin-bottom: 28px;
    }
  }
  .infos-dates {
    margin-bottom: 20px;
    color: $black;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.71;
    text-transform: uppercase;
    letter-spacing: 2.15px;
  }
  .infos-products {
    margin-bottom: 10px;
    color: $brand;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.41;
  }

  .infos-treatment {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
    li {
      @include flex(flex-start, flex-start, row);
      @media screen and (max-width: 639px) {
        display: list-item;
      }
      &:before {
        display: none;
        @media screen and (max-width: 639px) {
          display: block;
        }
      }
      span {
        @include flexGrow(1);
        &:first-of-type {
          width: 150px;
          min-width: 150px;
          max-width: 150px;
          @include flexGrow(0 1 150px);
          @media screen and (max-width: 639px) {
            width: inherit;
            min-width: inherit;
            max-width: inherit;
          }

          &:before {
            color: $brand;
            content: "•";
            float: left;
            margin-left: -1em;
            position: relative;
            top: 1px;
            @media screen and (max-width: 639px) {
              display: none;
            }
          }
        }
      }
    }
  }

  .item-nogdon {
    margin-left: 104px;
    @media screen and (max-width: 479px) {
      margin-left: 0;
    }
  }
}

body {

  .select2-container--default, .select2-container {
    .multiple {
      .select2-results__option--highlighted[aria-selected],
      .select2-results__option[aria-selected=true],
      .select2-results__option:hover {
        background-color: #f7f6f2 !important;
        color: #222228 !important;
      }

      .select2-results__option {
        .wrap {
          position: relative;
          padding-top: 8px;
          padding-bottom: 8px;
          margin-left: 45px;
          &:before {
            position: absolute;
            top: 50%;
            @include translateY(-50%);
            left: -45px;
            content: "";
            display: block;
            border: 1px solid #d8d8d8;
            @include square(30px);
          }

        }
      }
      .select2-results__option[aria-selected=true] {
        .wrap {
          &:before {
            background-color: $brand;
          }
        }
      }
    }
  }

}

.no-product-msg {
  margin-top: 40px;
  display: none;
}
