@import "mixins";
@import "variables";

/*[class^="ico"], [class*=" ico"] {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  image-rendering: -moz-crisp-edges; !* Firefox *!
  image-rendering: -o-crisp-edges; !* Opera *!
  image-rendering: -webkit-optimize-contrast; !* Webkit (non-standard naming) *!
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; !* IE (non-standard property) *!
}*/

//.ico-zoom {
//  @include square(20px);
//  background-image: url('#{$imgPath}/ico-zoom.png');
//}



/*
.ico-account {
  @include size(27px, 24px);
  fill: $white;
}
*/
.ico-arrow-btn {
  @include size(13px, 10px);
  fill: $white;
}

.ico-arrow-btn-long {
  @include size(47px, 13px);
  fill: $brand;
}

.ico-arrow-faq{
  min-width: 18px;
  max-width: 18px;
  @include flexGrow(0 1 18px);
  @include size(18px, 14px);
  fill: #d7d7d7;
}

.ico-eye,
.ico-faq,
.ico-marker,
.ico-search,
.ico-test{
  fill: $purple;
}

.ico-eye{
  @include size(24px, 16px);
}

.ico-faq{
  @include square(22px);
}

.ico-marker{
  @include size(16px, 20px);
}

.ico-search{
  @include square(18px);
}

.ico-test{
  @include size(22px, 19px);
}

.ico-play{
  @include size(12px, 18px);
  fill: $black;
}