/*  -------------------------------------------------------
    ------------------->>> VARIABLES <<<----------------------
    -------------------------------------------------------  */

//COLORS

$white: #fff;
$light: #f7f6f2;
$lightMedium: #eeebe6;
$grey: #999;
$greyMedium: #666;
$black: #222228;

$brand: #e63329;
$brand2: #dedc00;


$pink: #e4234c;
$pinkMedium: #ef2257;
$pinkDark: #a3063d;
$purple: #441c37;




$red: #e64954;
$green: #1fac74;


//FONTS
$fontPath: '../fonts/';

//IMAGES
$imgPath: '../img/';