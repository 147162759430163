/* Forms styles */
/* ---------------------------------- */

input:not([type=radio]):not([type=checkbox]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input, textarea, button {
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  transition: all .35s ease-in-out;
}

button[type="submit"]{
  margin-top: 30px;
  font-family: 'Avenir', sans-serif;
}


body {
  .select2-container, .select2-container--default {
    //min-width: 290px;
    font-family: 'Avenir', sans-serif;
    .select2-selection--single {
      background-color: $white;
      height: 55px;
      border-radius: 0;
      border: 1px solid #d7d7d7;
      font-family: 'Avenir', sans-serif;
      .select2-selection__rendered {
        padding-left: 30px;
        padding-right: 64px;
        height: 100%;
        font-family: 'Avenir', sans-serif;
        color: #4a4a4a;
        font-size: 16px;
        font-weight: 300;
        line-height: 55px;
      }
      .select2-selection__arrow {
        top: 50%;
        right: 27px;
        @include translateY(-50%);
      }
    }
    .select2-dropdown {
      border-radius: 0 !important;
      border: none !important;
      background-color: $light !important;
      &.select2-dropdown--below {
        border-top: none;
        border-bottom-left-radius: 0!important;
        border-bottom-right-radius: 0!important;
      }
    }
    .select2-search--dropdown {
      padding: 0 15px 8px 15px;
      .select2-search__field {
        border: 1px solid $light !important;
        color: $black !important;
      }
    }
    .select2-results__options {
      background-color: $light;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    .select2-results__option {
      padding: 10px 20px !important;
      font-size: 14px !important;
      color: $black !important;
      transition: all .35s ease-in-out;
      &[aria-selected=true] {
        background-color: #cbcaca;
      }
    }
    .select2-results__option--highlighted[aria-selected] {
      background-color: $brand !important;
      color: $white !important;
    }
  }
}

.form-row {
  @include flex(space-between, center, row);
  margin-bottom: 18px;
  margin-left: -12px;
  margin-right: -12px;

  @media screen and (max-width: 767px) {
    @include flexDirection(column);
    margin-bottom: 15px;
  }

  @media screen and (min-width: 640px) and (max-width: 767px) {
    .g-recaptcha {
      > div {
        margin-bottom: 15px;
      }
    }
  }

  &.form-row-submit{
    margin-top: 35px;
    @include flex(space-between, center, row);
    >*{
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.form-group {
  margin-left: 15px;
  margin-right: 15px;
  @include flexGrow(1);
  width: calc(100% - 30px);
  text-align: left;

  &:not(:last-of-type) {

    @media screen and (max-width: 767px) {
      margin-bottom: 18px;
    }
  }

  label, input, textarea, select {
    display: block;
    width: 100%;
  }


  .select2-container, .select2-container--default {
    width: 100% !important;
  }

  label{
    margin-bottom: 7px;
    color: $black;
    span{
      color: $brand;
    }
  }

  input:not([type="submit"]) {
    height: 55px;
    line-height: 55px;
  }
  textarea {
    padding-top: 19px;
    padding-bottom: 19px;
    height: 140px;
    line-height: 1.71;
  }
  input:not([type="submit"]), textarea {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 0;
    border: 1px solid #d7d7d7;
    color: #4a4a4a;
    font-weight: 300;
    font-size: 16px;
    font-family: 'Avenir', sans-serif;

    &::-webkit-input-placeholder {
      font-size: 16px;
      color: #4a4a4a;
      font-weight: 300;
    }
    &:-moz-placeholder {
      font-size: 16px;
      color: #4a4a4a;
      font-weight: 300;
    }
    &::-moz-placeholder {
      font-size: 16px;
      color: #4a4a4a;
      font-weight: 300;
    }
    &:-ms-input-placeholder {
      font-size: 16px;
      color: #4a4a4a;
      font-weight: 300;
    }
  }
  input[type="date"]{
    height: initial;
    line-height: normal;
    padding-top: 14px;
    padding-bottom: 15px;
  }


  &.has-error {
    input:not([type="submit"]), select, textarea, .select2-container--default .select2-selection--single, .select2-container .select2-selection--single, .file label {
      border: 2px solid $red !important;
    }
  }

}

.g-recaptcha {
  @media screen and (max-width: 639px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 359px) {
    max-width: 275px;
  }
  > div {
    @media screen and (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.error, &.wp-and-share-by-email-validation-error {
    iframe {
      border: 2px solid rgba(234, 72, 66, .6);
    }
  }
}

.alert, .custom-alert {
  margin-top: 20px;
  text-align: left;
  margin-bottom: 20px;
  padding: 8px 15px;
  border-radius: 4px;
  p {
    margin-bottom: 0;
    font-size: 13px !important;
  }
  &.error, &.custom-error {
    border: 2px solid darken($red, 6%);
    background-color: rgba($red, .1);
    p {
      color: $red !important;
    }
  }
  &.success, &.custom-success {
    border: 2px solid darken($green, 6%);
    background-color: rgba($green, .1);
    p {
      color: $green !important;
    }
  }
  &.hidden {
    display: none;
  }
}

.form-notice{
  margin-left: 15px;
  margin-right: 15px;
  color: $brand;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  @media screen and (max-width: 767px) {
    width: calc(100% - 30px);
    min-width: calc(100% - 30px);
    max-width: calc(100% - 30px);
    @include flexGrow(0 1 calc(100% - 30px));
  }
}

label + .radio,
label + .checkbox{
  margin-top: 18px;
}


.file{
  position: relative;

  input {
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    @include absCenter();
    width: 100%;
    height: 100%;
    padding: 0;
  }
  label {
    margin-bottom: 0;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    @include flex(space-between, center, row);
    @include flexWrap(wrap);
    padding: 2px 2px 2px 20px;
    height: 55px;
    border: 1px solid #d7d7d7;
    color: #d7d7d7;
    font-size: 16px;
    font-weight: 400;
    text-transform: none;

    @media screen and (max-width: 479px) {
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    }
    span {
      display: inline-block;
      //line-height: 51px;
      line-height: 1;
      color: #4a4a4a;
      font-weight: 300;
      @media screen and (max-width: 479px) {
       /* margin-top: 8px;
        margin-bottom: 8px;*/
        line-height: 1.2;
        font-size: 12px;
      }
      &:first-child{
        @include flexGrow(1);
      }
      &:last-child {
        margin: 0;
        padding: 18px 20px;
        background-color: #d7d7d7;
        color: $black;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
        transition: all .4s ease-in-out;
        line-height: 1;

        width: 93px;
        min-width: 93px;
        max-width: 93px;
        @include flexGrow(0 1 93px);
        @media screen and (max-width: 479px) {
          //margin-top: 16px;
          //margin-bottom: 8px;

        }
      }
    }
  }
  .notice{
    display: block;
    margin-top: -10px;
    margin-bottom: 8px;
    color: $greyMedium;
    font-size: 14px;
  }
}

.radio,
.checkbox {
  @include flex(flex-start, center, row);
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  width: auto;
  min-width: inherit;
  max-width: inherit;
  @include flexGrow(1 1 auto);
  label{
    margin-bottom: 0;
  }

  input {
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    @include absCenter();
    width: 100%;
    height: 100%;
    padding: 0;
  }
  input:checked + span {
    border-color: $brand;
    &:before {
      background-color: $brand;
    }
  }
  input:checked ~ label {
    color: $brand;
  }
  span {
    position: relative;
    display: block;
    margin-right: 12px;
    @include square(30px);
    min-width: 30px;
    max-width: 30px;
    @include flexGrow(0 1 30px);
    border: 1px solid #d7d7d7;
    transition: all .35s ease-in-out;
    &:before {
      @include absCenter();
      content: '';
      display: block;
      @include square(16px);
      background-color: transparent;
      transition: all .35s ease-in-out;
    }
  }
}

.radio{
  @include flexGrow(0);
  white-space: nowrap;
 &:not(:last-child){
   margin-right: 24px;
 }
  span{
    border-radius: 50%;
    &:before{
      border-radius: 50%;
    }
  }
}

.radio-ctn{
  @include flex(flex-start, center, row);
  @include flexWrap(wrap);
}


.form-fieldset{
  margin-top: 38px;
  margin-bottom: 32px;
  color: $greyMedium;
  font-size: 15px;
  line-height: 1.23;
  text-transform: uppercase;
}

form{
  p{
    &:not(:last-child){
      margin-bottom: 80px;
    }
  }
}

.form-control{
  &.hidden{
    position: absolute;
    left: -9999px;
    opacity: 0;
  }
}
.form-group.has-error {
  span.error {
    color: $red;
    font-size: 12px;
  }
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#FFFFFF, 0.9);
  width: 100%;
  height: 100%;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  -ms-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

body.loading {
  overflow: hidden;
  .loader {
    opacity: 1;
    visibility: visible;
  }
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $pink;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  } 
}