/*  -------------------------------------------------------
    ------------------->>> MIXINS <<<----------------------
    -------------------------------------------------------  */

// Flexbox
%flex {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin flex($justifyContent, $alignItems, $flexDirection) {
  @extend %flex;
  -webkit-justify-content: $justifyContent;
  -ms-flex-pack: $justifyContent;
  justify-content: $justifyContent;
  -webkit-align-items: $alignItems;
  -ms-flex-align: $alignItems;
  align-items: $alignItems;
  -webkit-flex-direction: $flexDirection;
  -ms-flex-direction: $flexDirection;
  flex-direction: $flexDirection;
}

@mixin justifyContent($string) {
  -webkit-justify-content: $string;
  -ms-flex-pack: $string;
  justify-content: $string;
}

@mixin alignItems($string) {
  -webkit-align-items: $string;
  -ms-flex-align: $string;
  align-items: $string;
}

@mixin order($integer) {
  -webkit-box-ordinal-group: $integer; /*OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-ordinal-group: $integer; /*OLD - Firefox 19- */
  -ms-flex-order: $integer; /*TWEENER - IE 10 */
  -webkit-order: $integer; /*NEW - Chrome */
  order: $integer;
}

@mixin flexWrap($string){
  -webkit-flex-wrap: $string;
  -ms-flex-wrap: $string;
  flex-wrap: $string;
}

@mixin flexDirection($string){
  -webkit-flex-direction: $string;
  -ms-flex-direction: $string;
  flex-direction: $string;
}

@mixin alignSelf($string){
  -webkit-align-self: $string;
  align-self: $string;
}

@mixin flexGrow($integer){
  -moz-box-flex: $integer;
  -webkit-flex: $integer;
  -moz-flex: $integer;
  -ms-flex: $integer;
  flex: $integer;
}


// Clearfix
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


// Position

@mixin position($position, $args: ()) {
  $offsets: top right bottom left;
  position: $position;

  @each $offset in $offsets {
    // Doing the magic trick
    // All this code happens inside the loop
    $index: index($args, $offset);

    // If offset is found in the list
    @if $index {

      // If it is found at last position
      @if $index == length($args) {
        #{$offset}: 0;
      }

        // If it is followed by a value
      @else {
        $next: nth($args, $index + 1);

        // If the next value is value length
        @if is-valid-length($next) {
          #{$offset}: $next;
        }

          // If the next value is another offset
        @else if index($offsets, $next) {
          #{$offset}: 0;
        }

          // If it is invalid
        @else {
          @warn "Invalid value `#{$next}` for offset `#{$offset}`.";
        }
      }
    }
  }
}



// Positioning helpers
@mixin absolute($args: ()) {
  @include position(absolute, $args);
}

@mixin fixed($args: ()) {
  @include position(fixed, $args);
}

@mixin relative($args: ()) {
  @include position(relative, $args);
}

@mixin absCenter{
  @include absolute(top 50% left 50%);
  @include translate(-50%,-50%);
}

/* Usage
@include absolute(top 100% left 0);
*/


// Keyframes
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

/* Usage
@include keyframes(animate) {
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
*/


// Animations
@mixin animation($name, $duration: 1000ms, $iterations: infinite, $timing-function: ease, $delay: 0ms, $animation-fill-mode: forwards, $animation-direction: alternate) {
  // There is a FF bug that requires all time values to have units, even 0 !!!!!!
  -webkit-animation: $name $duration $iterations $timing-function $delay $animation-fill-mode $animation-direction;
  -moz-animation: $name $duration $iterations $timing-function $delay $animation-fill-mode $animation-direction;
  -o-animation: $name $duration $iterations $timing-function $delay $animation-fill-mode $animation-direction;
  animation: $name $duration $iterations $timing-function $delay $animation-fill-mode $animation-direction;
}

@mixin transition {
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transition: all .35s #{$i * .1}s ease-in-out;
    }
  }
}

// Gradients
@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

/* Usage
@include gradient(#07c, #06f, vertical);
*/


// Vertical align
@mixin ghostVerticalAlign(){
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%; width: .1px;
  }
}


// Transform
@mixin transform($string) {
  -ms-transform: $string;
  -webkit-transform: $string;
  transform: $string;
}

// Rotation
@mixin rotate($rotation) {
  -ms-transform: rotate($rotation);
  -webkit-transform: rotate($rotation);
  transform: rotate($rotation);
}

// Scale
@mixin scale($scale) {
  -ms-transform: scale($scale);
  -webkit-transform: scale($scale);
  transform: scale($scale);
}

// Scale
@mixin scaleX($scale) {
  -ms-transform: scaleX($scale);
  -webkit-transform: scaleX($scale);
  transform: scaleX($scale);
}

// Scale
@mixin scaleY($scale) {
  -ms-transform: scaleY($scale);
  -webkit-transform: scaleY($scale);
  transform: scaleY($scale);
}

// Skew
@mixin skew($skew) {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;

  -webkit-transform: skew($skew) translateZ(0);
  -ms-transform: skew($skew) translateZ(0);
  transform: skew($skew) translateZ(0);
}

@mixin skewX($x) {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;

  -webkit-transform: skewX($x) translateZ(0);
  -ms-transform: skewX($x) translateZ(0);
  transform: skewX($x) translateZ(0);
}

@mixin skewY($y) {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;

  -webkit-transform: skewY($y) translateZ(0);
  -ms-transform: skewY($y) translateZ(0);
  transform: skewY($y) translateZ(0);
}

// Translate
@mixin translate($x,$y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin translate3d($x,$y, $z) {
  -webkit-transform: translate($x, $y, $z);
  -ms-transform: translate($x, $y, $z);
  transform: translate($x, $y, $z);
}

@mixin translateX($x) {
  -webkit-transform: translateX($x);
  -ms-transform: translateX($x);
  transform: translateX($x);
}

@mixin translateY($y) {
  -webkit-transform: translateY($y);
  -ms-transform: translateY($y);
  transform: translateY($y);
}

// Translate 3d
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}


// Placeholder
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

/* Usage
@include placeholder {
  font-style:italic;
  color: white;
  font-weight:100;
}*/


// Size
@mixin size($width, $height){
  width: $width;
  height: $height;
}

@mixin square($size){
  @include size($size, $size);
}

// Nth child query quantity
@mixin quantity-query( $number, $comparison: 'equal' ) {
  $nth: '';

  @if index( ('greater' 'more' '>'), $comparison ) {
    $nth: 'n + #{$number}';
  } @else if index( ('less' 'fewer' '<'), $comparison ) {
    $nth: '-n + #{$number}';
  } @else if index( ('equal' 'same' '='), $comparison ) {
    $nth: $number;
  } @else {
    @warn "Sorry, that's an invalid $comparison value."
  }

  &:nth-last-child(#{$nth}):first-child {
    &,
    & ~ * {
      @content;
    }
  }
}

@mixin qq-equal( $number ) {
  @include quantity-query( $number, equal ) {
    @content;
  }
}

@mixin qq-greater( $number ) {
  @include quantity-query( $number, greater ) {
    @content;
  }
}

@mixin qq-less( $number ) {
  @include quantity-query( $number, less ) {
    @content;
  }
}


